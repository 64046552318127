@import 'ds/styles/includes/index.scss';
.title {
    text-decoration: underline var(--neutral-500) dotted;
}
.content {
    max-width: 400px;
    border-radius: $primary-radius;
    white-space: normal;
    z-index: 1;
    background-color: var(--neutral-700);
    color: var(--neutral-200);
    font-weight: normal;
    line-height: 14px;
}

.arrow,
.arrow::before {
    position: absolute;
    width: 10px;
    height: 10px;
    background: inherit;
}

.arrow {
    visibility: hidden;
}

.arrow::before {
    visibility: visible;
    content: '';
    transform: rotate(45deg);
    border-top: 1px solid var(--neutral-200);
    border-left: 1px solid var(--neutral-200);
}
