@import 'ds/styles/includes/index.scss';

.btn {
    display: inline-block;
    white-space: pre;
    text-decoration: none;
    line-height: 14px;
    cursor: pointer;
    border-radius: $primary-radius;
    font-weight: 600;
    @include focus-outline();
}

.disabled {
    opacity: 0.5;
    cursor: not-allowed;
}

.fullWidth {
    width: 100%;
    display: block;
}

.left {
    text-align: left;
}

.round {
    border-radius: 25%;
}

.xs {
    padding: 4px 6px;
}

.sm {
    padding: 9px 12px;
}

.md {
    padding: 11px 16px;
}

.lg {
    padding: 14px 24px;
    font-size: 16px;
}

.xl {
    padding: 20px 44px;
    font-size: 16px;
}

.primary {
    border: 1px solid var(--primary-500);
    background-color: var(--primary-500);
    color: white;
    font-weight: 600;
    &:not(.disabled) {
        &:hover,
        &:active {
            border: 1px solid var(--primary-600);
            background-color: var(--primary-600);
        }
    }
}

.primarySubtle {
    border: 1px solid var(--primary-100);
    background-color: var(--primary-100);
    color: var(--primary-500);
    &:not(.disabled) {
        &:hover,
        &:active {
            background-color: var(--primary-100);
        }
    }
}

.primaryText {
    border: 1px solid transparent;
    color: var(--primary-500);
    background-color: transparent;
    font-weight: 600;
    &:not(.disabled) {
        &:active,
        &:hover {
            background-color: var(--primary-100);
            border: 1px solid var(--primary-100);
        }
    }
}

.secondary {
    border: 1px solid var(--neutral-300);
    color: var(--neutral-800);
    background-color: transparent;
    font-weight: 600;
    &:not(.disabled) {
        &:active,
        &:hover {
            background-color: var(--neutral-100);
            border: 1px solid var(--neutral-400);
        }
    }
}
.secondaryText {
    border: 1px solid transparent;
    // color: var(--neutral-800);
    color: inherit;
    background-color: transparent;
    font-weight: 600;
    &:not(.disabled) {
        &:active,
        &:hover {
            background-color: var(--neutral-200);
        }
    }
}

.success {
    border: 1px solid transparent;
    color: white;
    background-color: var(--success-500);
    font-weight: 600;
    transition: all 0.5s ease;
    cursor: default;
    pointer-events: none; // Disable interactions
}

.successText {
    border: 1px solid transparent;
    color: var(--success-500);
    background-color: transparent;
    font-weight: 600;
    &:not(.disabled) {
        &:active,
        &:hover {
            background-color: var(--success-100);
            border: 1px solid var(--success-100);
        }
    }
}

.danger {
    border: 1px solid var(--danger-500);
    background-color: var(--danger-500);
    color: var(--neutral-000);
    font-weight: 600;
    &:not(.disabled) {
        &:hover,
        &:active {
            border: 1px solid var(--danger-600);
            background-color: var(--danger-600);
        }
    }
}

.dangerSubtle {
    border: 1px solid var(--neutral-000);
    background-color: var(--neutral-000);
    color: var(--danger-500);
    &:not(.disabled) {
        &:hover {
            border: 1px solid var(--danger-100);
            background-color: var(--danger-100);
        }
        &:active {
            border: 1px solid var(--danger-100);
            background-color: var(--danger-100);
        }
    }
}
